@import "src/client_customizations/scss/client_master.scss";

.Footer {
       border-top: 1px solid rgba(50,49,76,.25);
       background-color: #ffffff;
       padding-top:50px;
       .disclaimer_container {
              // padding:25px 0;

              div {
                     display:flex;
                     justify-content: center;
                     flex-wrap: wrap;

                     img {
                            max-width: 100%;
                            margin-bottom:15px;
                     }

                     p {
                            color:#113653;
                            text-align: center;
                            margin:0;
                            font-size:12px;
                     }
              }

              .jdp-logo-footer {
                     display:block;
                     margin:0 auto;
              }
       }

       .logo-container {
              a.logo {
                     width:196px;
                     margin-right:40px;
                     display: inline-block;

                     img {
                            width:100%;
                            height:auto;
                     }
              }

              a.join-link {
                     background-color:#32314c;
                     padding: 9px 15px;
                     font-size:16px;
                     color:#ffffff;
                     font-weight: 500;
                     border-radius: 30px;
                     transition: all 0.2s ease-out;

                     &:hover {
                            background-color:#5b598a;
                            color:#ffffff;
                            text-decoration: none;
                     }
              }
       }

       .footer-nav-container {
              display:flex;
              justify-content:space-between;
              padding: 0;
              margin:50px 0;
              align-items: center;

              @media(max-width:991px) {
                     flex-wrap: wrap;
              }

              .footer-nav {
                     ul {
                            list-style-type: none;
                            padding:0;
                            margin:0;
                            display: flex;

                            @media(max-width:991px) {
                                   flex-wrap: wrap;
                                   margin-bottom: 25px;
                            }

                            li {
                                   padding:0 4px;

                                   @media(max-width:991px) {
                                          width: 100%;
                                   }

                                   &:first-child {
                                          a {
                                                 padding-left:0;
                                          }
                                   }

                                   a {
                                          padding: 9px 15px;
                                          font-size:16px;
                                          color:#32314c;
                                          font-weight: 400;
                                          border-radius: 30px;
                                          transition: all 0.2s ease-out;
                                          text-underline-offset: 0.4em;

                                          @media(max-width:991px) {
                                                 padding:0;
                                          }

                                          &:hover {
                                                 color: #4f4d63;
                                                 text-decoration: underline;
                                                 text-decoration-color: #39afc1;
                                          }
                                   }
                            }
                     }
              }

              .footer-social {
                     ul {
                            list-style-type: none;
                            padding:0;
                            margin:0;
                            display: flex;

                            li {
                                   padding:0 4px;
                                   margin-left:15px;

                                   &:first-child {
                                          margin-left:0;
                                   }

                                   a {
                                          padding: 9px 10px;
                                          background: #f5f5f6;
                                          border-radius: 50px;
                                          font-weight: 500;
                                          border-radius: 30px;
                                          transition: all 0.2s ease-out;
                                          width: 50px;
                                          height: 50px;
                                          display: flex;
                                          justify-content: center;
                                          align-items: center;

                                          &:hover {
                                                 svg {
                                                        fill:#32314c;
                                                 }
                                          }

                                          svg {
                                                 width:20px;
                                                 height:20px;
                                                 fill:#66647f;
                                          }
                                   }
                            }
                     }
              }
       }

       .footer-bottom {
              border-top: 1px solid rgba(50,49,76,.25);
              padding:15px 0;

              p.copyright {
                     margin:0;
                     font-size:14px;
              }
       }
}