@import "src/client_customizations/scss/client_master.scss";

.Header {
       width: 100%;
       padding:10px 20px;
       display: flex;
       justify-content: center;
       align-items: center;
       background-color:#fff;

       .navbar {
              padding:0;
              flex-wrap: nowrap;
       }

       .nav-button {
              .button {
                     position: relative;
                     top:-2px;
                     margin-right:30px;
                     cursor: pointer;

                     span {
                            width:27px;
                            height:3px;
                            background-color:#32314c;
                            display: block;
                            margin-bottom:4px;
                            margin:5px;
                            border-radius: 15px;

                            &:last-child {
                                   margin-bottom:0px;
                            }
                     }
              }
       }

       .logo-container {
              margin-right:100px;

              @media(max-width:500px) {
                     margin-right:15px;
              }

              img {
                     width: 180px;
              }
       }

       nav.main-menu {

              @media(max-width:991px) {
                     display:none;
              }

              ul {
                     position: relative;
                     top:3px;
                     list-style-type: none;
                     display: flex;
                     padding:0;
                     margin:0;

                     li {
                            padding:0 4px;

                            a {
                                   padding: 9px 15px;
                                   font-size:18px;
                                   color:#32314c;
                                   font-weight: 500;
                                   border-radius: 30px;
                                   transition: all 0.2s ease-out;

                                   &:hover {
                                          background-color:#32314c;
                                          color:#ffffff;
                                          text-decoration: none;
                                   }
                            }
                     }
              }
       }

       .nav-right {
              margin-left:auto;

              a {
                     background-color:#32314c;
                     padding: 9px 15px;
                     font-size:16px;
                     color:#ffffff;
                     font-weight: 500;
                     border-radius: 30px;
                     transition: all 0.2s ease-out;

                     &:hover {
                            background-color:#5b598a;
                            color:#ffffff;
                            text-decoration: none;
                     }
              }
       }

       .sidebar-container {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.7);
              display: flex;
              justify-content: start;
              align-items: center;
              z-index: 1000;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

              &.open {
                     opacity: 1;
                     visibility: visible;
              }

              .sidebar-wrapper {
                     width:360px;
                     height:100%;
                     position: relative;
                     overflow: hidden;

                     @media(max-width:768px) {
                            width:100%;
                     }
              }

              .sidebar {
                     width: 360px;
                     height: 100%;
                     background-color: #ffffff;
                     transition: transform 0.3s ease-out;
                     transform: translateX(-100%);
                     padding:50px 36px;
                     overflow-y: scroll;

                     @media(max-width:768px) {
                            width:100%;
                     }

                     &.open {
                            transform: translateX(0);
                     }

                     .logo-container {
                            display: flex;
                            margin:0 0 25px;
                            justify-content: space-between;

                            .close-button {
                                   top: 10px;
                                   right: 10px;
                                   background: transparent;
                                   border: none;
                                   font-size: 24px;
                                   cursor: pointer;
                                   color: #000;

                                   &:hover {
                                          color: red;
                                   }
                            }
                     }

                     .collapse {
                            display: block;
                            max-height: 0;
                            overflow: hidden;
                            transition: max-height 0.3s ease-out;

                            &.open {
                                   max-height: 450px;
                                   margin-top:10px;
                            }
                     }

                     nav {
                            ul {
                                   padding:0;
                                   margin:0;
                                   list-style-type: none;

                                   li {
                                          display: flex;
                                          align-items: center;
                                          flex-wrap: wrap;
                                          margin-bottom: 20px;

                                          a {
                                                 color: #32314c;
                                                 font-weight: 600;
                                                 font-size:24px;
                                                 padding:12px 5px 12px 0;
                                                 display: block;
                                                 line-height: 1em;

                                                 &:hover {
                                                        text-decoration: none;
                                                 }
                                          }

                                          button {
                                                 padding:0;
                                                 width:32px;
                                                 height:28px;
                                                 background-repeat: no-repeat;
                                                 background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 8.3751L0 2.3751L1.4 0.975098L6 5.5751L10.6 0.975098L12 2.3751L6 8.3751Z' fill='%2366647F'/%3E%3C/svg%3E%0A");
                                                 background-position: center center;
                                                 border:none;
                                                 background-color:transparent;

                                                 span {
                                                        display: none;
                                                 }
                                          }
                                   }

                                   &.sub-menu {
                                          margin:0;

                                          li {
                                                 margin-bottom: 10px;

                                                 span {
                                                        width:24px;
                                                        height:24px;
                                                        display: inline-block;
                                                        text-indent: -9999px;
                                                        background-repeat: no-repeat;
                                                        background-position: center center;
                                                        margin-right:15px;

                                                        &#arrow-circle-down {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 4.5C16.41 4.5 20 8.09 20 12.5C20 16.91 16.41 20.5 12 20.5C7.59 20.5 4 16.91 4 12.5C4 8.09 7.59 4.5 12 4.5ZM12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM13 12.5V8.5H11V12.5H8L12 16.5L16 12.5H13Z' fill='%2366647F'/%3E%3C/svg%3E%0A");
                                                        }

                                                        &#health-and-safety {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_228_2359)'%3E%3Cpath d='M10.5 13.5H8V10.5H10.5V8H13.5V10.5H16V13.5H13.5V16H10.5V13.5ZM12 2.5L4 5.5V11.59C4 16.64 7.41 21.35 12 22.5C16.59 21.35 20 16.64 20 11.59V5.5L12 2.5Z' fill='%2366647F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_228_2359'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                                                        }

                                                        &#house {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_228_2398)'%3E%3Cpath d='M19 9.8V4.5H16V7.1L12 3.5L2 12.5H5V20.5H10V14.5H14V20.5H19V12.5H22L19 9.8ZM10 10.5C10 9.4 10.9 8.5 12 8.5C13.1 8.5 14 9.4 14 10.5H10Z' fill='%2366647F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_228_2398'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                                                        }

                                                        &#light {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_228_2381)'%3E%3Cpath d='M13 6.56V3.5H11V6.56C6.5 7.06 3 10.87 3 15.49C3 16.6 3.9 17.5 5.01 17.5H8C8 19.71 9.79 21.5 12 21.5C14.21 21.5 16 19.71 16 17.5H18.99C20.1 17.5 21 16.6 21 15.49C21 10.87 17.5 7.06 13 6.56ZM12 15.5H5C5 11.64 8.14 8.5 12 8.5C15.86 8.5 19 11.64 19 15.5H12Z' fill='%2366647F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_228_2381'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                                                        }

                                                        &#restaurant {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_228_2365)'%3E%3Cpath d='M11 9.5H9V2.5H7V9.5H5V2.5H3V9.5C3 11.62 4.66 13.34 6.75 13.47V22.5H9.25V13.47C11.34 13.34 13 11.62 13 9.5V2.5H11V9.5ZM16 6.5V14.5H18.5V22.5H21V2.5C18.24 2.5 16 4.74 16 6.5Z' fill='%2366647F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_228_2365'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                                                        }

                                                        &#commute {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_228_2362)'%3E%3Cpath d='M12 4.5H5C3.34 4.5 2 5.84 2 7.5V15.5C2 17.16 3.34 18.5 5 18.5L4 19.5V20.5H5L7 18.47L9 18.5V13.5H4V6.48L13 6.5V8.5H15V7.5C15 5.84 13.66 4.5 12 4.5ZM5 14.5C5.55 14.5 6 14.95 6 15.5C6 16.05 5.55 16.5 5 16.5C4.45 16.5 4 16.05 4 15.5C4 14.95 4.45 14.5 5 14.5ZM20.57 10.16C20.43 9.76 20.05 9.5 19.6 9.5H12.41C11.95 9.5 11.58 9.76 11.43 10.16L10 14.27L10.01 19.78C10.01 20.16 10.32 20.5 10.7 20.5H11.32C11.7 20.5 12 20.12 12 19.74V18.5H20V19.74C20 20.12 20.31 20.5 20.69 20.5H21.3C21.68 20.5 21.99 20.16 21.99 19.78L22 18.41V14.27L20.57 10.16ZM12.41 10.5H19.6L20.63 13.5H11.38L12.41 10.5ZM12 16.5C11.45 16.5 11 16.05 11 15.5C11 14.95 11.45 14.5 12 14.5C12.55 14.5 13 14.95 13 15.5C13 16.05 12.55 16.5 12 16.5ZM20 16.5C19.45 16.5 19 16.05 19 15.5C19 14.95 19.45 14.5 20 14.5C20.55 14.5 21 14.95 21 15.5C21 16.05 20.55 16.5 20 16.5Z' fill='%2366647F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_228_2362'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                                                        }

                                                        &#diversity {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_228_2386)'%3E%3Cpath d='M4 16.5C5.10457 16.5 6 15.6046 6 14.5C6 13.3954 5.10457 12.5 4 12.5C2.89543 12.5 2 13.3954 2 14.5C2 15.6046 2.89543 16.5 4 16.5Z' fill='%2366647F'/%3E%3Cpath d='M1.22 18.08C0.48 18.4 0 19.12 0 19.93V21.5H4.5V19.89C4.5 19.06 4.73 18.28 5.13 17.6C4.76 17.54 4.39 17.5 4 17.5C3.01 17.5 2.07 17.71 1.22 18.08Z' fill='%2366647F'/%3E%3Cpath d='M20 16.5C21.1046 16.5 22 15.6046 22 14.5C22 13.3954 21.1046 12.5 20 12.5C18.8954 12.5 18 13.3954 18 14.5C18 15.6046 18.8954 16.5 20 16.5Z' fill='%2366647F'/%3E%3Cpath d='M22.78 18.08C21.93 17.71 20.99 17.5 20 17.5C19.61 17.5 19.24 17.54 18.87 17.6C19.27 18.28 19.5 19.06 19.5 19.89V21.5H24V19.93C24 19.12 23.52 18.4 22.78 18.08Z' fill='%2366647F'/%3E%3Cpath d='M16.24 17.15C15.07 16.63 13.63 16.25 12 16.25C10.37 16.25 8.93 16.64 7.76 17.15C6.68 17.63 6 18.71 6 19.89V21.5H18V19.89C18 18.71 17.32 17.63 16.24 17.15Z' fill='%2366647F'/%3E%3Cpath d='M9 12.5C9 14.16 10.34 15.5 12 15.5C13.66 15.5 15 14.16 15 12.5C15 10.84 13.66 9.5 12 9.5C10.34 9.5 9 10.84 9 12.5Z' fill='%2366647F'/%3E%3Cpath d='M2.48 11.36C2.17 10.6 2 9.86 2 9.1C2 6.52 4.02 4.5 6.6 4.5C9.28 4.5 10.42 6.24 12 8.09C13.57 6.26 14.7 4.5 17.4 4.5C19.98 4.5 22 6.52 22 9.1C22 9.86 21.83 10.6 21.52 11.36C22.17 11.67 22.7 12.18 23.05 12.8C23.65 11.6 24 10.38 24 9.1C24 5.4 21.1 2.5 17.4 2.5C15.31 2.5 13.31 3.47 12 5.01C10.69 3.47 8.69 2.5 6.6 2.5C2.9 2.5 0 5.4 0 9.1C0 10.38 0.35 11.6 0.96 12.8C1.31 12.18 1.84 11.67 2.48 11.36Z' fill='%2366647F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_228_2386'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                                                        }

                                                        &#local-activity {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 8.5C18 7.4 18.9 6.5 20 6.5V2.5C20 1.4 19.1 0.5 18 0.5H2C0.9 0.5 0.00999999 1.4 0.00999999 2.5V6.5C1.11 6.5 2 7.4 2 8.5C2 9.6 1.11 10.5 0 10.5V14.5C0 15.6 0.9 16.5 2 16.5H18C19.1 16.5 20 15.6 20 14.5V10.5C18.9 10.5 18 9.6 18 8.5ZM13.58 13.3L10 11L6.42 13.3L7.5 9.18L4.21 6.49L8.45 6.24L10 2.3L11.54 6.25L15.78 6.5L12.49 9.19L13.58 13.3Z' fill='%2366647F'/%3E%3C/svg%3E%0A");
                                                        }

                                                        &#credit-score {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 4.5C16.41 4.5 20 8.09 20 12.5C20 16.91 16.41 20.5 12 20.5C7.59 20.5 4 16.91 4 12.5C4 8.09 7.59 4.5 12 4.5ZM12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM13 12.5V8.5H11V12.5H8L12 16.5L16 12.5H13Z' fill='%2366647F'/%3E%3C/svg%3E%0A");
                                                        }

                                                        &#savings {
                                                               background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.83 6L15.56 3.73C15.63 3.31 15.74 2.92 15.88 2.58C15.96 2.4 16 2.21 16 2C16 1.17 15.33 0.5 14.5 0.5C12.86 0.5 11.41 1.29 10.5 2.5H5.5C2.46 2.5 0 4.96 0 8C0 11.04 2.5 19.5 2.5 19.5H8V17.5H10V19.5H15.5L17.18 13.91L20 12.97V6H17.83ZM11 7.5H6V5.5H11V7.5ZM14 9.5C13.45 9.5 13 9.05 13 8.5C13 7.95 13.45 7.5 14 7.5C14.55 7.5 15 7.95 15 8.5C15 9.05 14.55 9.5 14 9.5Z' fill='%2366647F'/%3E%3C/svg%3E%0A");
                                                        }
                                                 }

                                                 a {
                                                        color:#66647F;
                                                        font-size:18px;
                                                 }
                                          }
                                   }
                            }
                     }
              }
       }
}
