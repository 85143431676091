.label-select {  
  margin: 0 0 10px !important;

  font-family: 'TT Norms Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  text-transform: uppercase !important;

  color: #2A2A2A !important;
}