@import "src/client_customizations/scss/client_master.scss";

.IncentivesPage {
  div {
    text-align: center;
  }

  .subtitle {
    margin: 8px auto 30px;
    text-align: center;
    max-width: 540px;
  }

  .button {
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;
    width: 220px;

    background: #EB1700;
    border-radius: 2px;
    font-family: "TT Norms Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;

    color: #ffffff;
  }
}

.incentives-disclaimer {
       margin:20px 0 0;

       p {
              color:#666666;
              font-size:14px;
              line-height: 1.5em;
              font-weight: 300;
       }
}
