$color_1: #EB1700;
$color_2: #73767b;
$color_3: #f15c2a;
$color_4: #fff;
$color_5: #2b2b2b;
$font_family_1: "TT Norms Pro", "TT Norms Pro", Helvetica, sans-serif;
$background_color_1: #fff;
$background_color_2: white;
$background_color_3: #f2f2f2;
$background_color_4: #5ca4d1;
$background_color_5: #f3f4f4;
$background_color_6: #EB1700;
$background_color_7: #000000;
$border_color_1: transparent;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Thin.otf")
    format("Opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Thin Italic.otf")
    format("Opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro ExtraLight.otf")
    format("Opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro ExtraLight Italic.otf")
    format("Opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Light.otf")
    format("Opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Light Italic.otf")
    format("Opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Regular.otf")
    format("Opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Italic.otf")
    format("Opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Medium.otf")
    format("Opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Medium Italic.otf")
    format("Opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro DemiBold.otf")
    format("Opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro DemiBold Italic.otf")
    format("Opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Bold.otf")
    format("Opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Bold Italic.otf")
    format("Opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro ExtraBold.otf")
    format("Opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro ExtraBold Italic.otf")
    format("Opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Black.otf")
    format("Opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro Black Italic.otf")
    format("Opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro ExtraBlack.otf")
    format("Opentype");
  font-weight: 950;
  font-style: normal;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("../../../assets/fonts/TypeType - TT Norms Pro ExtraBlack Italic.otf")
    format("Opentype");
  font-weight: 950;
  font-style: italic;
}

body {
  background-color: $background_color_1;
  font-family: 'TT Norms Pro';
  font-size: 12px;
}
h1 {
  font-size: 24px;
}
.h1 {
  font-size: 24px;
}
h2 {
  font-size: 18px;
}
.h2 {
  font-size: 18px;
}
.page-title {
  font-size:36px;
  font-weight: 700;
  margin-bottom:0;
}
.modal-title {
  font-size: 24px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .modal-content {
    width: 150%;
  }
}
.modal {
  left: -5%;
}
h3 {
  font-size: 16px;
}
.h3 {
  font-size: 16px;
}
h4 {
  font-size: 12px;
}
.h4 {
  font-size: 12px;
}
h5 {
  font-size: 10px;
}
.h5 {
  font-size: 10px;
}
.lead {
  font-size: 18px;
  font-weight: 400;
}
a {
  color: $color_1;
}
table {
  table-layout: fixed;
  tbody {
    th {
      font-weight: normal;
    }
    tr {
      th {
        &:first-child {
          text-align: left;
        }
        word-break: break-all;
      }
    }
  }
  tfoot {
    tr {
      th {
        &:first-child {
          text-align: left;
        }
        word-break: break-all;
      }
    }
  }
}
table.table-bordered {
  tfoot {
    td {
      font-weight: bold;
      border-top: 2px solid #dee2e6;
    }
    th {
      font-weight: bold;
      border-top: 2px solid #dee2e6;
    }
  }
  th {
    word-break: break-all;
  }
}
button.btn-sm {
  font-size: 80%;
}
.MatchScoreOptions {
  p.h2 {
    margin-bottom: 25px;
  }

  &.input-well {
    .btn {
      position: relative;
      background-color: transparent;
      border: none;
      padding: 0px;
      color: $color_1;
      font-size: 14px;

      &:after {
        content: "\203A";
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}
.input-well {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 4px;
  box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);
  .form-group {
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 1rem;
    }
  }
  form {
    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  label {
    font-weight: 400;
    font-size: 12px;
    color: $color_5;
    text-transform: uppercase;
    margin-bottom: 10px;

    span {
       text-transform: none;
       font-style: italic;
       padding-left:5px;
       font-weight: 300;
    }
  }
  .label-style {
    font-weight: 400;
    font-size: 12px;
    color: $color_5;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .btn {
    background-color: $background_color_1;
    color: $background_color_6;
    display: inline-block;
    border: 1px solid #dbdbdb;
    font-weight: 400;
    font-size: 13px;
  }
  .btn.active {
    color: white;
    background-color: #EB1700;
    border: none;
  }
  .btn.filter {
    border-radius: 0px;
    height: 48px;
    display: inline;
    font-size: 14px;
    font-weight: 400;
  }
  .btn-block-container {
    display: block;
    width: 100%;
    margin-bottom: 6px;
    text-align: left;
    .badge {
      display: inline-block;
      padding: 0.25em 0.4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      img {
        width: 30px;
      }
    }
    .filter-label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;

      .name {
        display: inline-flex;
        white-space: nowrap;
      }
      .switch {
        position: absolute;
        left: 70%;
        .react-switch-bg {
          width: 50px !important;
        }
      }
    }
    .filter-switch {
      display: flex;
    }
    .first {
      margin-bottom: 10px;
    }
  }

  .btn-grid-container {
    display: flex;
    flex-wrap: wrap;
    button {
      width: 48%;
      margin: 2%;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(2n + 1) {
        margin-left: 0;
      }
    }
  }
  .btn-ae {
    padding: 6px 12px;
    border-radius: 0;
    border: 0;
    color: $color_4;
    font-weight: 600;
    background-color: $background_color_4;
  }
}
.btn-default {
  border: 1px solid #ced4da;
}
strong {
  font-weight: 500;
}
hr {
  border-top: 2px solid rgba(0, 0, 0, 0.8);
  margin: 0;
  padding: 0;
}
.arrow-up {
  top: calc((1rem - 1px) * -1);
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.3rem;
  &:before {
    position: absolute;
    display: block;
    content: "";
    border-color: $border_color_1;
    border-style: solid;
    top: 0;
    border-width: 0 1rem 1rem 1rem;
    border-bottom-color: $border_bottom_color_1;
  }
  &:after {
    position: absolute;
    display: block;
    content: "";
    border-color: $border_color_1;
    border-style: solid;
    top: 1px;
    border-width: 0 1rem 1rem 1rem;
    border-bottom-color: $border_bottom_color_2;
  }
}
.background-grey {
  background-color: $background_color_5;
}
.bottom-thick-margin {
  border-bottom: 2px solid #000000;
}
.color-primary {
  color: $color_3;
}
.btn-ae {
  padding: 6px 12px;
  border-radius: 0;
  border: 0;
  color: $color_4;
  font-weight: 600;
  background-color: $background_color_6;
  &:hover {
    border-radius: 0;
    color: $color_4;
    font-weight: 600;
    text-decoration: none;
    background-color: $background_color_6;
  }
}
.btn-ae-custom {
  font-size: 15px;
  padding: 6px 24px;
  border-radius: 0;
  border: 4px solid $color_1;
  color: $color_1;
  font-weight: 600;
  background-color: $background_color_1;
  &:hover {
    border-radius: 0;
    color: $color_1;
    font-weight: 600;
    text-decoration: none;
    background-color: $background_color_1;
  }
}
.btn-ae-custom-two {
  width: 100%;
  font-size: 15px;
  padding: 6px 24px;
  border-radius: 0;
  border: 4px solid $color_1;
  color: $color_4;
  font-weight: 600;
  background-color: $background_color_6;
  &:hover {
    border-radius: 0;
    color: $color_4;
    font-weight: 600;
    text-decoration: none;
    background-color: $background_color_6;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .btn-ae-custom,
  .btn-ae-custom-two {
    padding: 6px 5px;
  }
}

.btn-ae-custom-three {
  font-size: 16px;
  padding: 10px 25px;
  border-radius: 0;
  border: 1px solid lightgray;
  color: $color_1;
  font-weight: 400;
  background-color: white;
  &:hover {
    border-radius: 0;
    color: $color_1;
    font-weight: 600;
    text-decoration: none;
    background-color: $background_color_1;
  }
}
.btn-ae-custom-four {
  font-size: 16px;
  color: $color_5;
  font-weight: 400;
  border: none;
  background-color: inherit;
  cursor: pointer;
}
.btn-ae-custom-five {
  font-size: 15px;
  padding: 6px 24px;
  border-radius: 0;
  border: 4px solid $color_1;
  color: $color_4;
  font-weight: 400;
  background-color: $background_color_6;
  &:hover {
    border-radius: 0;
    color: $color_4;
    font-weight: 400;
    text-decoration: none;
    background-color: $background_color_6;
  }
}
.left-margin {
  margin-left: 20px;
}
.right-margin {
  margin-right: 20px;
}
.PageLayout {
  a.btn-ae {
    &:hover {
      border-radius: 0;
      color: $color_4;
      font-weight: 600;
      text-decoration: none;
      background-color: $background_color_6;
    }
  }
}
a.btn-ae {
  &:hover {
    border-radius: 0;
    color: $color_4;
    font-weight: 600;
    text-decoration: none;
    background-color: $background_color_6;
  }
}
#vehicle-sort-dropdown {
  background-position: 95%;
  background-size: 0.5em 0.5em;
  background-repeat: no-repeat;
  background-color: #fff;
}
.mb-0 {
  margin-top: 5px;
  color: $color_2;
}
.bottom-margin {
  margin-bottom: 40px;
}
#select-vehicle-age-filter {
  appearance: none;
  background: url(../../assets/images/icons/up-down-arrow.svg);
  background-position: calc(100% - 0.5rem), 100% 0;
  background-size: 0.75em 0.75em;
  background-repeat: no-repeat;
  &:focus {
    box-shadow: none;
    outline: 0;
  }
}
.map-header {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.map-header-title {
  font-weight: 700;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.wide {
  width: 100%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .select-ae {
    background-size: 0;
  }
}

.incentive-cards {
       justify-content: flex-start;

       @media(max-width:991px) {
              justify-content: center;
       }
}

.sidebar-disclaimer {
       border-top:1px solid #ccc;
       padding:40px 0 0;
       margin:30px 0 30px;

       p {
              margin:0;
              font-size:14px;
       }
}
